<template>
  <el-row>
    <el-col class="background">
      <div class="container" style="padding: 0">
        <div class="footer">
          <div class="clearfix footerCon">
            <div class="xb10 clearfix">
              <dl class="xb3">
                <dt>公共服务体系</dt>
                <dd>
                  <a>学历教育</a>
                </dd>
                <dd>
                  <a>赑风学习中心</a>
                </dd>
              </dl>
              <dl class="xb3">
                <dt>解决方案</dt>
                <dd>
                  <a>继续教育</a>
                </dd>
                <dd>
                  <a>高等院校</a>
                </dd>
                <dd>
                  <a>企业培训</a>
                </dd>
                <dd>
                  <a>校企合作</a>
                </dd>
                <dd>
                  <a>IT在线教育</a>
                </dd>
              </dl>
              <dl class="xb3">
                <dt>教育产品</dt>
                <dd>
                  <a>高等院校产品</a>
                </dd>
                <dd>
                  <a>企业培训产品</a>
                </dd>
                <dd>
                  <a>公共教育产品</a>
                </dd>
                <dd>
                  <a>IT教育产品</a>
                </dd>
                <dd>
                  <a>成人继续教育产品</a>
                </dd>
              </dl>
              <dl class="xb3">
                <dt>关于我们</dt>
                <dd>
                  <a>关于赑风</a>
                </dd>
                <dd>
                  <a>大数据与人工智能</a>
                </dd>
              </dl>
              <dl class="xb3">
                <dt>友情链接</dt>
                <dd>
                  <a href="https://qzjy.net.cn/">千舟教育学历报读</a>
                </dd>
                <dd>
                  <a>赑风IT</a>
                </dd>
                <dd>
                  <a>赑风成教平台</a>
                </dd>
              </dl>
            </div>
            <div class="xb2 erweima clearfix">
              <div class="fr">
               <img src="../../assets/img/erweima.jpg" />
                <p>扫一扫关注赑风微信</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row copyRight">
        <p>
          <span class="hidden-s hidden-m show-b">
            <a rel="nofollow" style="color: #ffffff" href="https://beian.miit.gov.cn">
              京ICP证17067671号 </a>
          </span>
          北京赑风科技有限公司 版权所有
        </p>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped >
@import "@/assets/css/footer/index.scss";
</style>
