<template>
  <div id="app">
    <div>
      <el-backtop
        :bottom="100"
        style="height: 50px; width: 50px; background-color: #f2f5f6"
      >
      </el-backtop>
    </div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from "./components/header/index.vue";
import Footer from "./components/footer/index.vue";
export default {
  components: { Header, Footer },
  data(){
    return {
      isRouterAlive:true,
    }
  },
  methods:{
  },
};
</script>
<style lang="scss">
.el-backtop {
  .el-icon-caret-top {
    background: url("assets/img/float_top.png") center center no-repeat;
    background-size: 20px;
  }
  .el-icon-caret-top::before {
    content: "\e78f";
    font-size: 16px;
    visibility: hidden;
  }
}
</style>
