var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[_c('el-col',[_c('Carouse')],1),_c('el-col',{style:({backgroundImage: 'url(' + require('@/assets/img/shouye/bg_cedu20.jpg') + ')' })},[_c('Introduce')],1),_c('el-col',{staticStyle:{"background":"#f0f2f5"}},[_c('Solution')],1),_c('el-col',[_c('Service')],1),_c('el-col',{style:({backgroundImage: 'url(' + require('@/assets/img/shouye/cp-box_bg.jpg') + ')',
      width: '100%',
      height: '100%',
    })},[_c('Innovate')],1),_c('el-col',{style:({backgroundImage: 'url(' + require('@/assets/img/shouye/zy-box_bg.jpg') + ')',
      width: '100%',
      height: '100%',
    })},[_c('Solve')],1),_c('el-col',{style:({backgroundImage: 'url(' + require('@/assets/img/shouye/gyhc_hysr_bg.jpg') + ')',
        width:'100%',
        height:'100%'
    })},[_c('Information')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }