<template>
  <div class="m-header">
    <div class="header">
      <div class="container" style="padding: 0 10px; overflow: visible">
        <div class="clearfix">
          <div class="nav-inner clearfix">
            <div class="nav-inner-header clearfix">
              <a class="logo">
                <img class="logo1" src="../../assets/img/logo.png" />
              </a>
            </div>
            <ul class="nav" id="nav">
              <li class="list">
                <a href="">首页</a>
              </li>
              <li class="list">
                <a>学历教育</a>
                <div class="menu-box menu-box-1">
                  <div class="menu-8">
                    <div class="menu-10">
                      <router-link :to="{ name: 'education' }"
                        >学历教育</router-link
                      >
                      <router-link :to="{ name: 'study' }"
                        >网络学习中心</router-link
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li class="menu-dlBox list">
                <a>解决方案</a>
                <div class="menu-box menu-box-1">
                  <div class="menu-9">
                    <div class="menu-11">
                      <dl class="menu-dl">
                        <dt>
                          <a>继续教育解决方案</a>
                        </dt>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>高校信息化解决方案</a>
                        </dt>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>企业培训解决方案</a>
                        </dt>
                        <dd>
                          <a>企业人效提升</a>
                        </dd>
                        <dd>
                          <a>基于大数据的人才发展</a>
                        </dd>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>融合教育解决方案</a>
                        </dt>
                        <dd>
                          <router-link :to="{ name: 'cooperation' }"
                            >校企合作</router-link
                          >
                        </dd>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>IT在线教育解决方案</a>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </li>
              <li class="menu-dlBox list">
                <a>教育产品</a>
                <div class="menu-box menu-box-1">
                  <div class="menu-9">
                    <div class="menu-11">
                      <dl class="menu-dl">
                        <dt>
                          <a>高等院校产品</a>
                        </dt>
                        <dd>
                          <a>智慧云课堂教学系统</a>
                        </dd>
                        <dd>
                          <a>第二课堂管理系统</a>
                        </dd>
                        <dd>
                          <a>创新创业实训平台</a>
                        </dd>
                        <dd>
                          <a>网络教育课程</a>
                        </dd>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>企业培训产品</a>
                        </dt>
                        <dd>
                          <a>企业培训课程</a>
                        </dd>
                        <dd>
                          <a>用到</a>
                        </dd>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>公共教育产品</a>
                        </dt>
                        <dd>
                          <a>LMS学习管理系统</a>
                        </dd>
                        <dd>
                          <a>BMS学习管理系统</a>
                        </dd>
                        <dd>
                          <a>大数据平台</a>
                        </dd>
                        <dd>
                          <a>学起移动教学平台</a>
                        </dd>
                        <dd>
                          <a>赑风移动教学平台</a>
                        </dd>
                        <dd>
                          <a>考试信息化</a>
                          <a>分布式云考试系统</a>
                        </dd>
                        <dd>
                          <a>智能云排课系统</a>
                        </dd>
                        <dd>
                          <a>赑风云LIVE直播平台</a>
                        </dd>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>IT教育产品</a>
                        </dt>
                      </dl>
                      <dl class="menu-dl">
                        <dt>
                          <a>成人继续教育产品</a>
                        </dt>
                        <dd>
                          <a>非学历培训平台</a>
                        </dd>
                        <dd>
                          <a>成教教学教务管理平台</a>
                        </dd>
                        <dd>
                          <a>网教教学教务管理平台</a>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </li>

              <li class="menu-dlBox list">
                <a>课程资源</a>
                <div class="menu-box menu-box-1">
                  <div class="menu-9">
                    <div class="menu-11">
                      <dl class="menu-dl menu-dl-3">
                        <dt>
                          <a>学历教育</a>
                        </dt>
                        <dd>
                          <a>资源联盟课程</a>
                        </dd>
                        <dd>
                          <a>网络教育课程</a>
                        </dd>
                      </dl>
                      <dl class="menu-dl menu-dl-3">
                        <dt>
                          <a>学历教育</a>
                        </dt>
                        <dd>
                          <a>IT实训课程</a>
                        </dd>
                        <dd>
                          <a>CG培训课程</a>
                          <a href="">CV培训课程</a>
                        </dd>
                      </dl>
                      <dl class="menu-dl menu-dl-3">
                        <dt>
                          <a>企业培训课程</a>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list">
                <a>资讯中心</a>
                <div class="menu-box menu-box-1">
                  <div class="menu-8">
                    <div class="menu-10">
                      <a>赑风资讯</a>
                      <a>赑风微视</a>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list">
                <a>关于我们</a>
                <div class="menu-box menu-box-1">
                  <div class="menu-8">
                    <div class="menu-10">
                      <router-link :to="{ name: 'about' }"
                        >关于赑风</router-link
                      >
                      <a>大数据&amp;人工智能</a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <button
              type="button"
              class="navbar-toggle"
              id="nav-btn"
              @click="neng"
            >
              <span class="sr-only">切换导航</span>
              <span class="icon-bar bar-1"></span>
              <span class="icon-bar bar-2"></span>
              <span class="icon-bar bar-3"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <el-row class="tac" v-show="show">
      <el-col>
        <el-menu
          class="el-menu-vertical-demo"
          text-color="#000"
          active-text-color="#ffd04b"
          :unique-opened="true"
        >
          <el-menu-item index="1">
            <span slot="title"><a href="">首页</a></span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <span>公共服务体系</span>
            </template>
            <el-menu-item-group>
              <router-link :to="{ name: 'education' }">
                <el-menu-item index="7-1" @click="neng"
                  ><a>学历教育</a></el-menu-item
                >
              </router-link>
              <router-link :to="{ name: 'study' }">
                <el-menu-item index="7-1" @click="neng"
                  ><a>网络学习中心</a></el-menu-item
                >
              </router-link>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="3">
            <template slot="title">
              <span>解决方案</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="3-1">继续教育解决方案</el-menu-item>
              <el-menu-item index="3-2">高效信息化解决方案</el-menu-item>
              <el-menu-item index="3-3">企业培训解决方案</el-menu-item>
              <el-menu-item index="3-4">融合教育解决方案</el-menu-item>
              <el-menu-item index="3-5">IT在线教育解决方案</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <span>教育产品</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="4-1">高等院校产品</el-menu-item>
              <el-menu-item index="4-2">企业培训产品</el-menu-item>
              <el-menu-item index="4-3">公共教育产品</el-menu-item>
              <el-menu-item index="4-4">IT教育产品</el-menu-item>
              <el-menu-item index="4-5">成人继续教育产品</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <span>课程资源</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="5-1">资源联盟课程</el-menu-item>
              <el-menu-item index="5-2">网络教育课程</el-menu-item>
              <el-menu-item index="5-3">IT实训课程</el-menu-item>
              <el-menu-item index="5-4">CG培训课程</el-menu-item>
              <el-menu-item index="5-5">企业培训课程</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <span>资讯中心</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="6-1">赑风资讯</el-menu-item>
              <el-menu-item index="6-2">赑风微视</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">
              <span>关于我们</span>
            </template>
            <el-menu-item-group>
              <router-link :to="{ name: 'about' }">
                <el-menu-item index="7-1" @click="neng"
                  ><a>关于赑风</a></el-menu-item
                >
              </router-link>

              <el-menu-item index="7-2">大数据&人工智能</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      flag: true,
    };
  },
  methods: {
    neng() {
      this.show = !this.show;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/header/index.scss";
</style>
