<template>
  <el-row>
    <el-col>
      <Carouse />
    </el-col>
    <el-col
          :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/bg_cedu20.jpg') + ')' }">
      <Introduce />
    </el-col>
    <el-col style="background: #f0f2f5">
      <Solution />
    </el-col>
    <el-col>
      <Service />
    </el-col>
    <el-col
        :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/cp-box_bg.jpg') + ')',
        width: '100%',
        height: '100%',
      }"
    >
      <Innovate />
    </el-col>
    <el-col
          :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/zy-box_bg.jpg') + ')',
        width: '100%',
        height: '100%',
      }"
    >
      <Solve />
    </el-col>
    <el-col
        :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/gyhc_hysr_bg.jpg') + ')',
          width:'100%',
          height:'100%'
      }"
    >
      <Information />
    </el-col>
  </el-row>
</template>
<script>
import Carouse from "./carouse.vue";
import Introduce from "./introduce.vue";
import Solution from "./solution.vue";
import Service from "./service.vue";
import Innovate from "./innovate.vue";
import Solve from "./solve.vue";
import Information from "./information.vue";
export default {
  components: {
    Carouse,
    Introduce,
    Solution,
    Service,
    Innovate,
    Solve,
    Information,
  },
};
</script>
<style>
.el-col{
      background-size: cover;
}
</style>
<style lang="scss">
@import "@/assets/css/product/index.scss";
</style>