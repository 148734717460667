<template>
  <div>
    <div class="project separate" id="project">
      <div class="container">
        <div class="list clearfix">
          <div class="item">
            <h2>专题</h2>
            <span class="imgSpan">
              <img
                src="../../assets/img/shouye/cedu_Pic01.jpg"
              />
            </span>
            <ul>
              <li><a >赑风科技：智慧融合，拥抱未来</a></li>
              <li><a >融合创新科技 赋能智慧教育</a></li>
              <li><a >校企携手，谋变创新，智赢未来</a></li>
              <li><a >赑风科技全面开启“智慧”模式</a></li>
            </ul>
          </div>
          <div class="item">
            <h2>视频</h2>
            <span class="imgSpan">
              <img
                 src="../../assets/img/shouye/cedu_Pic02.jpg"
                alt=""
              />
            </span>
            <ul>
              <li><a >拥抱变化 拥抱未来</a></li>
              <li><a >技术与时代合拍 让教育更智慧</a></li>
              <li><a >学习中心：服务源于专业 真诚赢得信赖</a></li>
              <li><a >业务拓展中心：深耕需求 诚信服务</a></li>
            </ul>
          </div>
          <div class="item">
            <h2>人物</h2>
            <span class="imgSpan">
              <img
                 src="../../assets/img/shouye/cedu_Pic03.jpg"
                alt=""
              />
            </span>
            <ul>
              <li><a >有血性、有行动、有向往</a></li>
              <li><a >将体验做到极致，将产品灵魂做到深处</a></li>
              <li><a >雷厉风行严苛睿智的财务“大管家</a></li>
              <li><a >执着于心，专注于行</a></li>
            </ul>
          </div>
        </div>
        <div class="understand">
          <span>了解更多</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.item{
  width: calc(30.3333333% - 20px)
}
.imgSpan {
  position: relative;
  width: 100%;
  display: inline-block;
  line-height: 0;
  overflow: hidden;
}
@media (max-width: 759px) {
    .container{
        padding-left: 6px;
        padding-right: 6px;
    }
}
</style>