<template>
  <div class="region">
    <div class="title">
      <h2>公共服务体系</h2>
      <i></i>
    </div>
    <div class="container">
      <ul
        class="ggfw-box"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/shouye/bg-ggA.png') + ')',
          width: '100%',
          height: '100%',
        }"
      >
        <li
          class=""
          @mouseover="enter($event)"
          @mouseout="leave($event)"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/img/shouye/bg-ggL.png') + ')',
          }"
        >
          <h6>学历教育</h6>
          <p>
            赑风教育针对不同行业、用户，提供灵活多样的服务模式，保障院校快速启动、稳健发展。
          </p>
          <div class="ggfw-con clearfix">
            <dl>
              <dt>
                <div class="gg-head wj01"></div>
                <div class="gg-tit">全面合作</div>
              </dt>
              <dd>
                <p>
                  首创网络教育整体服务模式，提供体制创新服务、运营咨询规划、市场品牌建设、人才团队搭建等在内的系统性服务。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="gg-head wj02"></div>
                <div class="gg-tit">技术合作</div>
              </dt>
              <dd>
                <p>
                  针对不同院校业务需求，提供多元化教育产品和个性化解决方案，以及系统运维、业务咨询与管理等服务，拥有成熟的经验和成功的案例。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="gg-head wj03"></div>
                <div class="gg-tit">招生合作</div>
              </dt>
              <dd>
                <p>
                  现代远程教育公共服务体系赑风学习中心，以规范化、标准化的线上、线下招生服务平台…为合作院校输送优质生源。
                </p>
              </dd>
            </dl>
          </div>
          <div class="tc">
            <a class="more-pc" >了解更多</a>
          </div>
        </li>
        <li
          class=""
          @mouseover="enter($event)"
          @mouseout="leave($event)"
          :style="{
            backgroundImage:
             'url(' + require('@/assets/img/shouye/bg-ggR.png') + ')',
          }"
        >
          <h6>赑风学习中心</h6>
          <p>
            2007年，赑风学习中心成为现代远程教育公共服务体系试点单位，为院校学员提供数字化学习支持服务。
          </p>
          <div class="ggfw-con clearfix">
            <dl>
              <dt>
                <div class="gg-head xxzx01"></div>
                <div class="gg-tit">自建管理模式</div>
              </dt>
              <dd>
                <p>全国建设50余家学习中心，帮助30余万网络大学生提升学历。</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="gg-head xxzx02"></div>
                <div class="gg-tit">标准化运营管理</div>
              </dt>
              <dd>
                <p>
                  标准化学习中心建设、管理、服务团队培养等，保障优质的服务质量。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="gg-head xxzx03"></div>
                <div class="gg-tit">全面质量监控</div>
              </dt>
              <dd>
                <p>
                  通过学习中心自查、区域经理监察等质量控制流程，确保服务质量。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="gg-head xxzx04"></div>
                <div class="gg-tit">高质量学生服务</div>
              </dt>
              <dd>
                <p>
                  一站式咨询、实时助学服务，丰富的学生活动及个性化的学习支持。
                </p>
              </dd>
            </dl>
          </div>
          <div class="tc">
            <a class="more-pc" >了解更多</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    enter(e) {
      e.currentTarget.className = "active";
    },
    leave(e) {
      e.currentTarget.className = "";
    },
  },
};
</script>
<style>
</style>
<style lang="scss" scoped>
@import "@/assets/css/product/service.scss";
</style>