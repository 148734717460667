<template>
  <div class="container" id="innovate">
    <div class="title">
      <h2 style="color: #fff">教育创新产品</h2>
      <i></i>
    </div>
    <div class="mCon tab-box">
      <div
        class="side-con"
        :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/tab_cpbg_cj.png') + ')',
          width: '100%',
          height: '100%',
        }"
      >
        <ul class="sidebar">
          <li
            class="sidebar-item hover"
            v-for="(item, index) of nav.list"
            :key="index"
            @mouseenter="enter(index, $event)"
            @mouseleave="leave($event)"
          >
            <a >
              <i class="cp-01"></i>
              <h3>{{ item.t }}</h3>
              <p>{{ item.te }}</p>
            </a>
          </li>
        </ul>
        <ul class="content hidden-s hidden-m">
          <li
            class="content-item"
            v-show="seen && index == current"
            v-for="(item, index) of nav.list"
            :key="index"
          >
            <div class="header-section">
              <h3>{{ item.text }}</h3>
              <p>{{ item.tet }}</p>
              <ul class="clearfix">
                <li>
                  <a 
                    ><i class="icon cj-01"></i
                    ><span>非学历培训<br />平台</span></a
                  >
                </li>
                <li>
                  <a 
                    ><i class="icon cj-02"></i
                    ><span>成教教学教务<br />管理平台</span></a
                  >
                </li>
                <li>
                  <a 
                    ><i class="icon cj-03"></i
                    ><span>网教教学教务<br />管理平台</span></a
                  >
                </li>
              </ul>
              <a  class="more btnHover">了解更多<i></i></a>
            </div>
          </li>
        </ul>
      </div>
      <div class="content content-mob hidden-b">
        <div
          class="content-mob-item"
          v-for="(item, index) of nav.list"
          :key="index"
        >
          <div class="sidebar-tit" data-id="cj">
            <a
              id="tab-index-cj"
              style="border-color: transparent"
              @click="buneng(index)"
            >
              <i class="cp-01"></i>
              <h3>{{ item.t }}</h3>
              <p>{{ item.te }}</p>
            </a>
          </div>
          <div class="header-section" v-show="show === index && showAll">
            <h3>{{ item.text }}</h3>
            <p>{{ item.tet }}</p>
            <ul class="clearfix">
              <li>
                <a 
                  ><i class="icon cj-01"></i><span>非学历培训平台</span></a
                >
              </li>
              <li>
                <a 
                  ><i class="icon cj-02"></i><span>成教管理平台</span></a
                >
              </li>
              <li>
                <a 
                  ><i class="icon cj-03"></i><span>网教管理平台</span></a
                >
              </li>
            </ul>
            <a class="more btnHover">了解更多<i></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        list: [
          {
            tab: "all",
            t: "成人继续教育方向",
            te: "高起点打造信息化教育品牌",
            text: "成人继续教育产品",
            tet: "针对成人继续教育院校需求，深入开展现代信息技术与网络教育融合模式的研究，不断研发新产品，建设具有国内领先水平的网络教育产品体系，保证院校业务高效稳定的开展",
          },
          {
            tab: "food",
            t: "高等院校方向",
            te: "加速推进教学改革",
            text: "高等院校产品",
            tet: "面向全日制高等院校，在教学、管理，价值观与技能培养等方面全面创新教育产品，构建新型教学与管理模式，打造科学有序、因材施教的教育生态。",
          },
          {
            tab: "spa",
            t: "IT教育方向",
            te: "全面培养高端职业人",
            text: "IT教育产品",
            tet: " 通过提供教学平台、课程资源建设，实现产品协同、服务协同，AI大数据精准教学，让更多用户享受到智慧教育带来的优质服务和全新体验。",
          },
          {
            tab: "dalian",
            t: "企业培训方向",
            te: "助力企业人效提升",
            text: "企业培训产品",
            tet: "以用到云在线学习平台为核心，搭载丰富学习资源，提供精细化运营服务，致力于为企事业单位、公共机构组织构建基于人工智能、大数据、云计算的企业学习及人才发展方案，助力组织提升效能，实现长远发展。",
          },
          {
            tab: "taiyuan",
            t: "公共产品方向",
            te: "个性需求全面满足",
            text: "公共教育产品",
            tet: "以大数据、云计算、人工智能等信息技术为支撑，创新智慧教学与管理模式，为用户提供标准化的教育产品，同步满足不同行业、用户需求。",
          },
        ],
      },
      show: -1,
      kind: "all",
      showAll: false,
      seen: true,
      current: 0,
    };
  },
  methods: {
    enter(index) {
      this.current = index;
      this.seen = true;
    },
    leave(e) {
      e.currentTarget.className = "";
    },
    buneng(index) {
      this.show = index;
      this.showAll = !this.showAll;
    },
  },
};
</script>
<style scoped>
.container {
  margin: 0 auto 50px;
}
.active {
  background-color: brown;
}
</style>
<style lang="scss" >
@import "@/assets/css/product/innovate.scss";
</style>