<template>
  <div class="home">
    <Product />
  </div>
</template>

<script>

import Product from "../components/product/index.vue";

export default {
  name: "Home",
  components: {Product},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
body,
ul,
li,
dl,
dt,
dd,
p,
h1,
h2,
h3,
h4,
h6 {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
</style>
