<template>
  <div class="region" id="solution">
    <div class="title">
      <h2>教育行业解决方案</h2>
      <i></i>
    </div>
    <div class="container">
      <ul class="FA-list" style="margin: 0; padding: 0">
        <li>
          <div class="FA-info">
            <div class="FA-Pic">
              <img src="../../assets/img/shouye/FA-Pic01@2x.jpg" />
              <div class="Fa-PicMask"></div>
            </div>
            <div class="FA-con">
              <h6>继续教育</h6>
              <p>从事学历教育服务17年，500所合作高校，服务模式灵活多样</p>
              <div class="clearfix fabtn">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
          <div class="FA-mask">
            <img src="../../assets/img/shouye/FA-Pic01H.jpg" />
            <div class="FA-maskBg"></div>
            <div class="FA-maskCon">
              <dl>
                <dt><font style="vertical-align: inherit">继续教育</font></dt>
                <dd>提供体制创新、运营规划、品牌建设等整体服务</dd>
                <dd>提供全方位教育产品与解决方案</dd>
                <dd>依托规范平台为院校输送优质生源</dd>
                <dd>助力院校打造领先教育信息化品牌</dd>
              </dl>
              <div class="tc">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="FA-info">
            <div class="FA-Pic">
              <img src="../../assets/img/shouye/FA-Pic02@2x.jpg" />
              <div class="Fa-PicMask"></div>
            </div>
            <div class="FA-con">
              <h6>高校信息化</h6>
              <p>打通线上线下壁垒，创建更具开放性的网络教育模式</p>
              <div class="clearfix fabtn">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>

          <div class="FA-mask">
            <img src="../../assets/img/shouye/FA-Pic02H.jpg" />
            <div class="FA-maskBg"></div>
            <div class="FA-maskCon">
              <dl>
                <dt>高校信息化</dt>
                <dd>提供资源、平台及个性化定制等一站式服务</dd>
                <dd>构建新型教育模式和教学环境</dd>
                <dd>推进优质教育资源共建共享</dd>
                <dd>帮助高校加速教改，提升教学效果</dd>
              </dl>
              <div class="tc">
                <a class="more-pc">了解更多<i></i></a>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="FA-info">
            <div class="FA-Pic">
              <img src="../../assets/img/shouye/FA-Pic03@2x.jpg" />
              <div class="Fa-PicMask"></div>
            </div>
            <div class="FA-con">
              <h6>企业培训</h6>
              <p>
                以人工智能技术为企业定制人才发展解决方案，帮助企业实现人员与业务高度匹配
              </p>
              <div class="clearfix fabtn">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
          <div class="FA-mask">
            <img src="../../assets/img/shouye/FA-Pic03H.jpg" />
            <div class="FA-maskBg"></div>
            <div class="FA-maskCon">
              <dl>
                <dt><font style="vertical-align: inherit">企业培训</font></dt>
                <dd>提供企业信息化所需的一切软硬件环境</dd>
                <dd>提供项目定制、深度运营等专业化服务</dd>
                <dd>帮助企业建设精品UGC课程库</dd>
                <dd>促成企业人才发展与管理体系化</dd>
              </dl>
              <div class="tc">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="FA-info">
            <div class="FA-Pic">
              <img src="../../assets/img/shouye/FA-Pic04@2x.jpg" />
              <div class="Fa-PicMask"></div>
            </div>
            <div class="FA-con">
              <h6>融合教育</h6>
              <p>
                搭建行业企业与高校的合作桥梁，以企业需求推动人才供给侧教学提升
              </p>
              <div class="clearfix fabtn">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
          <div class="FA-mask">
            <img src="../../assets/img/shouye/FA-Pic04H.jpg" />
            <div class="FA-maskBg"></div>
            <div class="FA-maskCon">
              <dl>
                <dt><font style="vertical-align: inherit">融合教育</font></dt>
                <dd>提供专业共建、实习实训、信息化平台及资源服务</dd>
                <dd>提供项目实践和企业实战等辅导培训</dd>
                <dd>产学合作促学校与企业用人需求无缝对接</dd>
                <dd>助力高校解决人才供给侧难题</dd>
              </dl>
              <div class="tc">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="FA-info">
            <div class="FA-Pic">
              <img src="../../assets/img/shouye/FA-Pic05@2x.jpg" />
              <div class="Fa-PicMask"></div>
            </div>
            <div class="FA-con">
              <h6>IT在线教育</h6>
              <p>提供覆盖IT全职业段的在线教育培训，专注培养高端IT职业人</p>
              <div class="clearfix fabtn">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
          <div class="FA-mask">
            <img src="../../assets/img/shouye/FA-Pic05H.jpg" />
            <div class="FA-maskBg"></div>
            <div class="FA-maskCon">
              <dl>
                <dt><font style="vertical-align: inherit">IT在线教育</font></dt>
                <dd>打造测、练、评一体化的特色授课模式</dd>
                <dd>企业级项目落地拆解，实战技能快速进阶</dd>
                <dd>保姆式1对1技术辅导和就业辅导</dd>
                <dd>数百家合作企业，定期高薪直聘</dd>
              </dl>
              <div class="tc">
                <a class="more-pc"
                  ><font style="vertical-align: inherit">了解更多</font><i></i
                ></a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>