import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path:'/education',
    name:'education',
    component: (resolve) => require(['../components/header/children/education'], resolve)
  },
  {
    path:'/study',
    name:'study',
    component:() => import('../components/header/children/study')
  },
  {
    path:'/cooperation',
    name:'cooperation',
    component:() => import('../components/header/children/cooperation')
  },
  {
    path:'/about',
    name:'about',
    component:() => import('../components/header/children/about')
  }
];

const router = new VueRouter({
  routes,
});

export default router;
