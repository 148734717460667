<template>
  <div class="project information" id="information">
    <div class="container">
      <div class="title">
        <h2 style="color: #fff">资讯中心</h2>
        <i></i>
      </div>
      <div class="list clearfix" style="padding: 60px 0">
        <div class="item">
          <div class="clearfix">
            <a class="qwe">
              <span class="imgSpan">
                <img
                  src="../../assets/img/shouye/20210922105426241.jpg"
                  alt=""
                />
              </span>
              <h2>搭建“学分银行”，希望更多人爱上终身学习</h2>
              <p class="time">2021-09-30</p>
            </a>
          </div>
        </div>
        <div class="item">
                    <div class="clearfix">
          <a  class="qwe">
            <span class="imgSpan">
              <img
                src="../../assets/img/shouye/20210907021807867.jpg"
                alt=""
              />
            </span>
            <h2>“派单”、学分互认，赑风综合培训管理系统再升级</h2>
            <p class="time">2021-09-27</p>
          </a>
                    </div>
        </div>
        <div class="item">
                    <div class="clearfix">
          <a  class="qwe">
            <span class="imgSpan">
              <img
                src="../../assets/img/shouye/20210816110543284.jpg"
                alt=""
              />
            </span>
            <h2>赑风云直播打造在线教学新模式</h2>
            <p class="time">2021-09-22</p>
          </a>
                    </div>
        </div>
        <div class="item">
                    <div class="clearfix">
          <a  class="qwe">
            <span class="imgSpan">
              <img
                src="../../assets/img/children/jjfa3_wl_pic06@2x.jpg"
                alt=""
              />
            </span>
            <h2>拥抱变化 拥抱未来</h2>
            <p class="time">2020-11-11</p>
          </a>
                    </div>
        </div>
        <div class="item">
                    <div class="clearfix">
          <a  class="qwe">
            <span class="imgSpan">
              <img
                src="../../assets/img/shouye/20201111025837711.jpg"
                alt=""
              />
            </span>
            <h2>技术与时代合拍 让教育更智慧</h2>
            <p class="time">2020-11-11</p>
          </a>
                    </div>
        </div>
        <div class="item">
                    <div class="clearfix">
          <a  class="qwe">
            <span class="imgSpan">
              <img
                src="../../assets/img/shouye/20201111100708672.jpg"
                alt=""
              />
            </span>
            <h2>业务拓展中心：深耕需求 诚信服务</h2>
            <p class="time">2020-11-11</p>
          </a>
                    </div>
        </div>
      </div>
      <div class="understand">
        <span>了解更多</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.information .qwe {
  width: 100%;
  img{
    width: 100%;
  }
  h2 {
    height: 50px;
  }
}
</style>