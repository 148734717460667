<template>
  <div class="project container solve" id="information">
    <div class="title">
      <h2>课程资源解决方案</h2>
      <i></i>
    </div>
    <div class="clearfix resourceList mCon">
      <div class="xs12 xm4 xb4">
        <div
          class="A"
          :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/zy-boxA.jpg') + ')'}"
          @mouseover="enter($event)"
          @mouseout="leave($event)"
        >
          <h6><i></i>学历教育</h6>
          <p>
            整合并打造完善的学历教育课程资源体系，全面满足不同教育领域的多元化用户需求。
          </p>
          <div class="zy-con clearfix">
            <dl>
              <dt>
                <div class="zy-head xl01"></div>
                <div class="zy-tit">继续教育&nbsp;<br />资源联盟课程</div>
              </dt>
              <dd>
                <p>
                  服务于成人继续教育高校、教育主管单位及广大师生。各类名校名课有助于解决教育资源发展不均衡、重复开发等问题。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="zy-head xl02"></div>
                <div class="zy-tit">全日制教育&nbsp;<br />网络教育课程</div>
              </dt>
              <dd>
                <p>
                  根据大学生学习现状与特点，研发思政和双创课程，采用多元化教学形式，使学生树立科学的创业观和价值观。
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="xs12 xm4 xb4">
        <div
          class="B"
          :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/zy-boxB.jpg') + ')'}"
          @mouseover="enter($event)"
          @mouseout="leave($event)"
        >
          <h6><i></i>职业培训</h6>
          <p>
            依托赑风丰富的院校资源，服务于成教高校、教育主管单位及广大师生，力促教育公平。
          </p>
          <div class="zy-con clearfix">
            <dl>
              <dt>
                <div class="zy-head zy01"></div>
                <div class="zy-tit">IT在线课程</div>
              </dt>
              <dd>
                <p>
                  课程体系全面覆盖从零基础入门到高阶提升的所有必修知识、技能，满足不同领域、岗位人群的多样化学习需求。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <div class="zy-head zy02"></div>
                <div class="zy-tit">CG在线课程</div>
              </dt>
              <dd>
                <p>
                  将企业实际所需的知识和技能直接教授给学生，从根本上解决学校里学的知识技能同现实工作需求脱轨的问题。
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="xs12 xm4 xb4">
        <div
          class="C"
          :style="{backgroundImage: 'url(' + require('@/assets/img/shouye/zy-boxC.jpg') + ')'}"
          @mouseover="enter($event)"
          @mouseout="leave($event)"
        >
          <h6><i></i>企业培训</h6>
          <p>
            针对新生代人才特点，着眼于解决企业业务中的实际问题，助力企业人才发展和绩效提升。
          </p>
          <div class="zy-con clearfix">
            <dl style="width: 100%">
              <dt>
                <div class="zy-head qy01"></div>
                <div class="zy-tit">PGC+UGC课程</div>
              </dt>
              <dd>
                <p>
                  PGC+UGC课程资源体系适用于多种企业培训场景，如岗位人才培养、人才发展进阶和关键业务技能培养等。
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="understand">
      <span>了解更多</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    enter(e) {
      e.currentTarget.className = "In";
    },
    leave(e) {
      e.currentTarget.className = "";
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/product/solve.scss";
</style>