<template>
  <div class="carouse">
    <el-carousel >
      <el-carousel-item v-for="item in imgList" :key="item.img">
        <img :src="item.img" :alt="item.alt" width="100%" height="100%" />
        <div class="slide-con container">
          <h2>{{ item.title }}</h2>
          <p>{{ item.text }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="azs">
      <div class="container" style="padding: 0">
        <ul class="">
          <li>
            <strong>17</strong>
            <p>深耕教育行业17年<br />提供专业且系统的教育服务</p>
          </li>
          <li>
            <strong>10</strong>
            <p>基于大数据及人工智能技术<br />创新10+教育科技产品</p>
          </li>
          <li>
            <strong>1000</strong>
            <p>合作院校及企业多达1000+<br />赋能全行业教育模式升级</p>
          </li>
          <li>
            <strong>2000</strong>
            <p>累计服务学员多达2000万+<br />助力终身学习型社会建设</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgList: [
        {
          img: require('../../assets/img/shouye/banner4.jpg'),
          title: "智慧考试正当时",
          text: "赑风考试网站上线，试用活动火热进行中",
        },
        {
          img: require('../../assets/img/shouye/banner3.jpg'),
          title: "赑风教育10年：智慧融合，拥抱未来",
          text: "将科技和教育智慧融合，深耕教与学的深层需求",
        },
        {
         img: require('../../assets/img/shouye/banner2.jpg'),
          title: "快乐学习，人生无限可能",
          text: "秉承“诚信 务实 创新 双赢”服务理念，为客户创造价值",
        },
        {
         img: require('../../assets/img/shouye/banner1.jpg'),
          title: "科技融合教育，让教育更智慧",
          text: "智慧科技驱动产品创新，智慧产品赋能教育变革",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.el-carousel-item {
  img {
    background-repeat: no-repeat;
    background-position: center;
  }
}
.carouse .el-carousel__indicators--horizontal {
  color: #000000;
  bottom: 22%;
}
@media (max-width: 960px) {
  .carouse .el-carousel__indicators--horizontal {
    bottom: 0;
  }
}
</style>
